<template>
  <div class="information">
    <b-row class="mb-4">
      <b-col cols="12">
        <ItemLine
          v-if="procedure.item_type === 'App\\Models\\ClinicProcedure'"
          :item="procedure"
        />
        <ItemLine
          v-else
          :item="{
            ...procedure,
            name: procedure?.clinic_procedure?.name || procedure?.clinic_procedure_by_item?.name, 
            item: { type: 'SURGICAL' }
          }"
        />
      </b-col>
    </b-row>

    <b-row class="mb-3">
      <b-col cols="12">
        <p>
          Lateralidade: <span>{{ procedure.laterality || '-' }}</span>
        </p>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12 mb-3">
        <p>
          Observações: <span>{{ procedure.observations || '-' }}</span>
        </p>
      </b-col>
    </b-row>

    <b-row
      class="mb-3"
      v-for="participant in orderedParticipants"
      :key="participant.id"
    >
      <b-col cols="12">
        <p>
          {{ participant.type }}:
          <span>
            {{ participant?.beneficiary?.name ?? participant.custom_name }}
          </span>
        </p>
      </b-col>
    </b-row>
    <b-button
      v-show="manageMatMed"
      variant="primary"
      v-b-tooltip.hover
      class="d-flex align-items-center"
      :disabled="!surgeryInformationId"
      @click="goToMatMedManagement"
    >
      <Clipboard class="clipboard" />
      Gerenciar Mat/med
    </b-button>
    <hr />
  </div>
</template>

<script>
export default {
  name: 'Procedure',
  props: {
    procedure: {
      type: Object,
      default: () => ({})
    },
    surgeryInformationId: String,
    manageMatMed: Boolean
  },
  components: {
    ItemLine: () => import('@/components/General/ItemLine'),
    Clipboard: () => import('@/assets/icons/clipboard.svg')
  },
  computed: {
    orderedParticipants() {
      const orderedParticipants = this.procedure?.participants
      return orderedParticipants.sort(function (a, b) {
        const order = [
          'Executante',
          'Instrumentador(a)',
          'Anestesista',
          'Auxiliar'
        ]
        const indexA = order.indexOf(a.type)
        const indexB = order.indexOf(b.type)
        return indexA - indexB
      })
    }
  },
  methods: {
    goToMatMedManagement() {
      this.$router.push(`matmed/${this.surgeryInformationId}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.clipboard {
  transform: scale(0.7);
  stroke: var(--neutral-100);
}
.procedure {
  color: var(--type-active);
  white-space: nowrap;
}
</style>
